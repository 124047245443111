import { createSlice } from '@reduxjs/toolkit';

export const ThemeSlice = createSlice({
  name: 'themeDart',
  initialState: {
    dark: false,
    light: true,
  },
  reducers: {
    setDarkTheme: (state, action) => {
      state.dark = action.payload;
    },
    setLightTheme: (state, action) => {
      state.light = action.payload;
    },
  },
});

export const { setDarkTheme, setLightTheme } = ThemeSlice.actions;

export default ThemeSlice.reducer;
