import { createSlice } from '@reduxjs/toolkit';

export const calSlice = createSlice({
  name: 'calculator',
  initialState: {
    calculatorData: [],
  },
  reducers: {
    setCalculatorData: (state, action) => {
      state.calculatorData = action.payload;
    },
  },
});

export const { setCalculatorData } = calSlice.actions;

export default calSlice.reducer;