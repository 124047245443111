import React, { lazy, Suspense } from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
// import App from './App';
import reportWebVitals from './reportWebVitals';
import { store } from './components/redux/store';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { logo } from './components/icons/icon';
import { Spinner } from 'react-bootstrap';
const App = lazy(() => import('./App'))
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <Provider store={store}>
    <React.StrictMode>
      <BrowserRouter>
        <Suspense fallback={
          <div className='' style={{ minHeight: '100vh', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <div className='' style={{ textAlign: 'center', display: 'flex', gap: '1rem', flexWrap: 'wrap', justifyContent: 'center' }}>
              <img src={logo} alt='' style={{ height: '2rem', display: 'inline-block' }} />
              <Spinner animation='grow' />
            </div>
          </div>
        }>
          <App />
        </Suspense>
      </BrowserRouter>
    </React.StrictMode>
  </Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
